.commit-box {
    padding: 50px 30px 0 30px;
    margin-bottom: 13px;
    .submit-btn-group {
        margin-top: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ::v-deep .tox .tox-tbtn svg {
        fill: #409EFF;
    }
    ::v-deep .tox-toolbar {
        background-color: #dddddd;
    }
    ::v-deep .tox-statusbar__text-container {
        display: none;
    }
}